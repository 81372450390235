<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="/admin/#/podlist">podHub</a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="btn-group" dropdown>
    <a
      id="button-basic"
      dropdownToggle
      type="button"
      class="dropdown-toggle"
      aria-controls="dropdown-basic"
    >
      {{ this.getName(this.router.url) }}<span class="caret"></span>
    </a>
    <ul
      id="dropdown-basic"
      *dropdownMenu
      class="dropdown-menu"
      role="menu"
      aria-labelledby="button-basic"
    >
      <li role="menuitem" *ngFor="let item of this.items">
        <a
          [ngClass]="{ active: item.path == this.router.url }"
          class="dropdown-item"
          href="/admin/#{{ item.path }}"
          >{{ item.name }}</a
        >
      </li>
    </ul>
  </div>
  <div *ngIf="info" class="ml-4">{{ info }}</div>

  <div class="btn ml-auto" style="float: right" (click)="this.logout()">
    Logout
  </div>
</nav>
<div class="container-fluid my-5">
  <div class="row">
    <div class="col-10 offset-1">
      <ng-content></ng-content>
    </div>
  </div>
</div>
