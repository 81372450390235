export interface MenuItem {
  name: string;
  path: string;
}

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/AuthService';

@Component({
  selector: 'app-pagewrapper',
  templateUrl: './pagewrapper.component.html',
  styleUrls: ['./pagewrapper.component.scss'],
})
export class PagewrapperComponent implements OnInit {
  @Input() info: any;
  @Input() current: any;
  public items: MenuItem[] = [];
  constructor(public router: Router, public authService: AuthService) {
    this.items.push({ name: 'Pods', path: 'podlist' });
    this.items.push({ name: 'Nutzer', path: 'userlist' });
    this.items.push({ name: 'Aktivierungen', path: 'podauthlist' });
  }
  public logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
  public getName(url: string) {
    let r = '';
    this.items.forEach((item) => {
      if (item.path === url.split('/')[1]) {
        r = item.name;
      }
    });
    return r;
  }

  ngOnInit(): void {}
}
