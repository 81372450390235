<app-pagewrapper [info]="dataObject.name">
  <lib-my2card-form
    [title]="dataObject.name"
    [dataObject]="this.dataObject"
    [populateWith]="this.fieldService.fc.user"
    [primaryFields]="['name', 'email']"
    [columns]="1"
    [editable]="true"
  ></lib-my2card-form>
  <div class="row mt-4">
    <div class="col text-right">
      <lib-my2-awesome-magic-deletebutton
        (onDeleted)="this.onUserDelete($event)"
        [fieldData]="
          this.fieldService.fc.user.__deletebutton.fieldData(this.dataObject)
        "
      ></lib-my2-awesome-magic-deletebutton>
    </div>
  </div>
</app-pagewrapper>
