<app-pagewrapper>
  <lib-dataleech-table-standalone
    #activationTable
    title="Pod Aktivierungen"
    [initialPageSize]="100"
    [autoexpand]="true"
    [primaryFields]="[
      'podIdent',
      'connectAccount',
      'creationDate',
      'lastRequested',
      'terminationDate'
    ]"
    dataDisplayMode="table"
    datastorename="auth"
    sortColumn="createdAt"
    sortDirection="desc"
    routerlinkIndexField="_id"
    [populateWith]="this.fieldService.fc.podAuth"
  >
  </lib-dataleech-table-standalone>
</app-pagewrapper>
