<app-pagewrapper>
  <div class="span pb-5">
    <app-authidcard
      [myDataObject]="this.dataObject"
      [showedit]="false"
    ></app-authidcard>
  </div>

  <h3>Ändern</h3>
  <lib-my2card [title]="">
    Aktiv bis:
    <input
      type="text"
      placeholder="Ablaufdatum"
      class="form-control col-4"
      #dateInput
      bsDatepicker
      (ngModelChange)="this.onModelChange($event)"
      [(ngModel)]="this.selectedDate"
      [bsConfig]="{ dateInputFormat: 'DD.MM.YYYY', returnFocusToInput: true }"
    />
    <!--    <app-podauthedit [podAuth]="this.dataObject"></app-podauthedit>-->
  </lib-my2card>
</app-pagewrapper>
