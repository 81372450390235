<app-pagewrapper>
  <div class="text-right mb-2">
    <lib-dataleech-create-button
      [fieldCreater]="this.fieldService.fc.pod.podIdent"
      requiredField="podIdent"
      promptmsg="Bitte Podid eingeben"
      [initialDataObect]="{ demodays: 30 }"
      (onCreated)="this.onNewPodCreated($event)"
    >
      Neuer Pod
    </lib-dataleech-create-button>
  </div>
  <lib-dataleech-table-standalone
    title="Pods"
    [autoexpand]="true"
    [primaryFields]="['name', 'description', 'podIdent', 'demodays']"
    dataDisplayMode="table"
    [showPagination]="false"
    [showPageSize]="false"
    datastorename="pod"
    [initialPageSize]="9999999999"
    sortColumn="createdAt"
    [showDisplayModeSwitcher]="false"
    sortDirection="desc"
    routerlinkIndexField="_id"
    [showColumnSettings]="false"
    [populateWith]="this.fieldService.fc.pod"
    (createButtonAction)="this.onNewPodCreated($event)"
  >
  </lib-dataleech-table-standalone>
</app-pagewrapper>
