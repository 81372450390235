import { ActivatedRoute, Router } from '@angular/router';
import { PodAuth } from './../../shared/PodHubTypes';
import { FieldService } from './../../FieldService';
import * as moment from 'moment';
import {
  DataleechDetailsComponent,
  NgDataleechService,
} from '@specialforce/ng-dataleech';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-podauthdetails',
  templateUrl: './podauthdetails.component.html',
  styleUrls: ['./podauthdetails.component.scss'],
})
export class PodauthdetailsComponent
  extends DataleechDetailsComponent<PodAuth>
  implements OnInit {
  public _selectedDate: Date;
  constructor(
    public activatedRoute: ActivatedRoute,
    public dataleechService: NgDataleechService<PodAuth>,
    public fieldService: FieldService,
    public router: Router
  ) {
    super(activatedRoute, dataleechService);
    this.datastoreName = 'auth';
  }
  public async onModelChange(e) {
    let m = moment(e).endOf('day').toDate().toISOString();
    const fieldCreator = this.fieldService.fc.podAuth.terminationDate;

    let o = await fieldCreator.backend.service
      .update(fieldCreator.fieldData(this.dataObject), m)
      .toPromise();
    this.dataObject = o.updated;
  }

  public set selectedDate(d: Date) {
    this._selectedDate = d;
  }
  public get selectedDate() {
    return this._selectedDate;
  }
  afterLoadData() {
    console.log(this.dataObject.terminationDate);
    this.selectedDate = moment(this.dataObject.terminationDate).toDate();
  }
}
