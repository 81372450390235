import { Injectable } from '@angular/core';
import {
  FieldCreator,
  HttpUpdateClient,
  NoUpdateService,
} from '@specialforce/ng-dataleech';
@Injectable({
  providedIn: 'root',
})
export class FieldService {
  constructor(
    private myHttpUpdateClient: HttpUpdateClient,
    private noUpdateService: NoUpdateService
  ) {}

  public get fc() {
    const podsBackend = { service: this.myHttpUpdateClient, type: 'pod' };
    const podsAuthBackend = { service: this.myHttpUpdateClient, type: 'auth' };
    const userBackend = { service: this.myHttpUpdateClient, type: 'users' };
    const fsFilesBackend = {
      service: this.myHttpUpdateClient,
      type: 'fs.files',
    };
    const noUpdateBackend = { service: this.noUpdateService, type: 'noupdate' };

    return {
      user: {
        _id: new FieldCreator('_id', '_id', noUpdateBackend),
        name: new FieldCreator('name', 'name', userBackend),
        superadmin: new FieldCreator('Admin', 'superadmin', userBackend),

        email: new FieldCreator('EMail', 'email', userBackend),
        __deletebutton: new FieldCreator(
          'Löschen',
          '__deletebutton',
          userBackend
        ),
        __createbutton: new FieldCreator('Neu', '__createbutton', userBackend, {
          creationObject: {
            email: '',
            name: 'Neuer Nutzer',
            password: '',
            superadmin: false,
            connectAccount: '',
          },
        }),
      },
      pod: {
        _id: new FieldCreator('_id', '_id', noUpdateBackend),
        podIdent: new FieldCreator('POD-ID', 'podIdent', podsBackend),
        name: new FieldCreator('Name (Sprechend)', 'name', podsBackend),
        description: new FieldCreator(
          'Beschreibung',
          'description',
          podsBackend,
          { editorType: 'textarea' }
        ),
        demodays: new FieldCreator(
          'Demo Tage',
          'demodays',
          podsBackend,
          { editorType: 'number' }
          ),
        __deletebutton: new FieldCreator(
          'Löschen',
          '__deletebutton',
          podsBackend
        ),
        __createbutton: new FieldCreator('Neu', '__createbutton', podsBackend, {
          creationObject: { podIdent: 'LEER', description: '' , demodays:0},
        }),
      },
      podversion: {
        filename: new FieldCreator('filename', 'Dateiname', fsFilesBackend),
        uploadDate: new FieldCreator(
          'uploadDate',
          'Hochgeladen am',
          noUpdateBackend,
          { displayType: 'moment_date' }
        ),
        length: new FieldCreator('length', 'length', noUpdateBackend),
        metadata: {
          podIdent: new FieldCreator('podIdent', 'POD-ID', fsFilesBackend),
          version: new FieldCreator('version', 'Version', fsFilesBackend),
          comment: new FieldCreator(
            'comment',
            'Versionskommentar',
            fsFilesBackend
          ),
          name: new FieldCreator('name', 'Metaname', fsFilesBackend),
          size: new FieldCreator('size', 'Größe', noUpdateBackend),
          lastModified: new FieldCreator(
            'lastModified',
            'Zuletzt geändert',
            fsFilesBackend,
            { displayType: 'moment_date' }
          ),
        },
      },
      podAuth: {
        _id: new FieldCreator('_id', '_id', noUpdateBackend),
        podIdent: new FieldCreator('POD-ID', 'podIdent', noUpdateBackend),
        requestedPodVersion: new FieldCreator(
          'Aktivierte Version',
          'requestedPodVersion',
          podsAuthBackend
        ),
        lastRequested: new FieldCreator(
          'Letzte Anfrage',
          'lastRequested',
          podsAuthBackend,
          { displayType: 'moment_date' }
        ),
        terminationDate: new FieldCreator(
          'Aktiv bis',
          'terminationDate',
          podsAuthBackend,
          { displayType: 'moment_date' }
        ),
        comment: new FieldCreator('Kommentar', 'comment', podsAuthBackend, {}),
        creationDate: new FieldCreator(
          'Erste Anfrage',
          'creationDate',
          podsAuthBackend,
          { displayType: 'moment_date' }
        ),
        connectAccount: new FieldCreator(
          'URL',
          'connectAccount',
          podsAuthBackend
        ),
      },
    };
  }
}
