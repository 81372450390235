import { PodauthdetailsComponent } from './podauthlist/podauthdetails/podauthdetails.component';
import { PodauthListComponent } from './podauthlist/podauthlist.component';
import { UserdetailsComponent } from './userlist/userdetails/userdetails.component';
import { UserlistComponent } from './userlist/userlist.component';
import { PoduploadComponent } from './podupload/podupload.component';
import { PodlistComponent } from './podlist/podlist.component';
import {AdminGuard} from './auth/admin.guard';
import {AuthGuard} from './auth/auth.guard';
import { P404Component } from './error/404.component';
import { P500Component } from './error/500.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PoddetailsComponent } from './podlist/poddetails/poddetails.component';
import { NgAuthComponent, NgRecoverpwComponent } from '@specialforce/ng-common-components';

const routes: Routes = [{
  path: '',
  redirectTo: 'login',
  pathMatch: 'full',
},
{
  path: '404',
  component: P404Component,
  data: {
    title: 'Page 404'
  }
},
{
  path: '500',
  component: P500Component,
  data: {
    title: 'Page 500'
  }
},
{
  path: 'login',
  component: NgAuthComponent,
  data: {
    title: 'Login',
    imagePath: 'assets/podhublogo.png'
  }
},
{
  path: 'recoverpw/:key',
  component: NgRecoverpwComponent,
  data: {
    title: 'Passwort zurücksetzen',
    imagePath: 'assets/podhublogo.png'
  }
},

{
  path: 'podauthlist',
  component: PodauthListComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Liste der aktivierten Pods'
  }
},
{
  path: 'podauthlist/:id',
  component: PodauthdetailsComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'PodAuthdetails'
  }
},
{
  path: 'podlist',
  component: PodlistComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Liste der Pods'
  }
},
{
  path: 'podlist/:id',
  component: PoddetailsComponent,
  canActivate: [AuthGuard],
  data: {
    title: 'Poddetails'
  }
},
{
  path: 'userlist',
  component: UserlistComponent,
  canActivate: [AuthGuard, AdminGuard],
  data: {
    title: 'Nutzeradmin'
  }
},
{
  path: 'userlist/:id',
  component: UserdetailsComponent,
  canActivate: [AuthGuard, AdminGuard],
  data: {
    title: 'Nutzeradmin'
  }
},
{
  path: '*',
  redirectTo: 'login'
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
