<ng-template #authidcard let-type="type" let-rowData="rowData">
  <app-authidcard [myDataObject]="rowData"></app-authidcard>
</ng-template>
<app-pagewrapper [info]="this.dataObject.podIdent">
  <div
    class="div"
    *ngIf="this.dataObject.podIdent == 'LEER' || this.changePodidentDialog"
  >
    <h2>
      Neuer Pod, bitte einen <b>einmaligen</b> und <b>eindeutigen</b>
      <b>podIdent</b> vergeben
    </h2>
    <div class="alert alert-danger">
      <lib-my2-awesome-form
        [title]="'Pod'"
        [dataObject]="this.dataObject"
        [populateWith]="this.fieldService.fc.pod"
        [primaryFields]="['podIdent']"
        [columns]="1"
        [editable]="true"
      ></lib-my2-awesome-form>
      <lib-my2-awesome-magic-deletebutton
        (onDeleted)="this.onPodDelete($event)"
        [fieldData]="
          this.fieldService.fc.pod.__deletebutton.fieldData(this.dataObject)
        "
      ></lib-my2-awesome-magic-deletebutton>
    </div>
  </div>
  <div
    class="div"
    *ngIf="this.dataObject.podIdent != 'LEER' && !this.changePodidentDialog"
  >
    <h2>
      Pod Attribute für >{{ this.dataObject.podIdent }}<
      <small
        *ngIf="this.podVersions.length == 0"
        style="float: right"
        class="btn-sm btn-danger"
        (click)="this.changePodidentDialog = !this.changePodidentDialog"
        >Podident doch noch mal ändern</small
      >
    </h2>
    <hr />
    <lib-my2-awesome-form
      [title]="'Pod'"
      [dataObject]="this.dataObject"
      [populateWith]="this.fieldService.fc.pod"
      [primaryFields]="['name', 'description', 'demodays']"
      [formHorizontal]="true"
      [columns]="3"
      [editable]="true"
    >
    </lib-my2-awesome-form>

    <div class="row">
      <div class="col-4" *ngIf="this.dataObject != null">
        <h3 *ngIf="this.podVersions.length == 0">
          Lade die erste version hoch&nbsp;
        </h3>
        <h3 *ngIf="this.podVersions.length > 0">
          Versionen&nbsp;
          <i
            class="ri-add-circle-line text-primary"
            (click)="this.nvdisplay = !this.nvdisplay"
            style="position: relative; top: 5px; cursor: pointer"
          ></i>
        </h3>

        <div
          *ngIf="this.nvdisplay || this.podVersions.length == 0"
          class="alert alert-primary"
        >
          <app-podupload
            *ngIf="this.dataObject != null"
            [podIdent]="this.dataObject.podIdent"
            (uploadedVersion)="loadPodVersions()"
          ></app-podupload>
        </div>

        <table
          class="table table-striped bg-white m-0"
          *ngIf="this.podVersions.length > 0"
        >
          <thead class="thead-light">
            <tr>
              <th style="width: 0px">V</th>
              <th>Kommentar</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let pVersion of this.podVersions">
              <td>
                <a
                  href="/download/{{ this.dataObject.podIdent }}/{{
                    pVersion.metadata.version
                  }}"
                >
                  {{ pVersion.metadata.version }}
                </a>
              </td>
              <td>
                {{ pVersion.metadata.comment }}
                <i
                  title="{{ this.formatDate(pVersion.uploadDate) }} {{
                    formatBytes(pVersion.length, 2)
                  }}"
                >
                </i>
              </td>
              <!--            <td>
              {{ this.formatDate(pVersion.uploadDate) }}
            </td>

            <td>
              {{ formatBytes(pVersion.length, 2) }}
            </td>-->
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-8">
        <div class="alert alert-info text-right">
          <a
            target="_blank"
            href="https://gitlab.rag.local/htmlpods/{{
              this.dataObject.podIdent
            }}"
          >
            <i class="ri-gitlab-line"></i>
            GITLAB</a
          >
          <a
            class="pl-5"
            target="_blank"
            href="https://gitlab.rag.local/htmlpods//{{
              this.dataObject.podIdent
            }}/-/issues"
            >Issues</a
          >
        </div>
        <lib-dataleech-table-standalone
          *ngIf="this.dataObject != null"
          title="Aktivierungen"
          [cardsPerColumn]="2"
          [showSearch]="false"
          [showInfo]="false"
          [showDisplayModeSwitcher]="true"
          [showSettingsBarAtTop]="false"
          [idCardTemplate]="authidcard"
          [autoMouseEventForIdCard]="false"
          [dataDisplayMode]="'list'"
          [autoexpand]="true"
          [primaryFields]="[
            'connectAccount',
            'requestedPodVersion',
            'creationDate',
            'lastRequested',
            'terminationDate'
          ]"
          dataDisplayMode="table"
          datastorename="auth"
          sortColumn="createdAt"
          sortDirection="desc"
          routerlinkIndexField="_id"
          [populateWith]="this.fieldService.fc.podAuth"
          [matchState]="{ podIdent: this.dataObject.podIdent }"
        >
        </lib-dataleech-table-standalone>
      </div>
    </div>
  </div>
</app-pagewrapper>
