import { NgBunyanLoggerService } from '@specialforce/ng-bunyan-logger';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from './AuthService';
import {catchError, exhaustMap, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import { HttpErrorService } from './http-error.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private httpErrorService: HttpErrorService, protected logger:NgBunyanLoggerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!localStorage.getItem('authTokenPodHub')) {
          this.authService.logout();
          return next.handle(req);
      }
      const modifiedRequest: HttpRequest<any> = req.clone({setParams: {token: localStorage.getItem('authTokenPodHub')}});
      return next.handle(modifiedRequest).pipe(
          catchError((err: any, caught: Observable<HttpEvent<any>>) => {

                this.httpErrorService.setError(err);
                this.logger.warn("AuthInterceptorService HttpError", err)
              if (err.status === 401) {
                  this.authService.logout();
              }
              return of(err);
          })
      );
  }
}
