<div class="row">
  <div class="col-12">
    <div class="form-row">
      <!--
      <div class="form-group col-md-3">
        <label for="version">Version</label>
        <input
          type="text"
          class="form-control"
          id="version"
          placeholder="Versionsnummer"
          name="version"
          [(ngModel)]="version"
        />
      </div>
      -->
      <small>Neue Version anlegen</small>
      <input
        type="text"
        class="form-control w-100 mb-3"
        id="comment"
        placeholder="Kommentar"
        name="comment"
        [(ngModel)]="comment"
      />
    </div>
    <!--
            <div class="uploads-table">
                <div *ngFor="let item of uploads" class="row">                    <label for="progress">{{item.name}}</label>
                    <progressbar id="progress" max="100" [value]="item.progress" type="info" [striped]="true" [animate]="true"><i>{{ item.status }}</i></progressbar>
                </div>
            </div>
            -->
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div
      uploadxDrop
      style="border: 3px dotted grey; height: 100px; text-align: center"
      class="mb-3 bg-white"
    >
      <img src="assets/img/DropFilesImage.jpg" style="height: 100%" />
      <label class="file-drop">
        <input
          type="file"
          style="display: none"
          [uploadx]="getUploadxOptions()"
          (state)="onStateChanged($event)"
        />
      </label>
    </div>
  </div>
</div>
