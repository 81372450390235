import { FieldService } from './../FieldService';
import { User } from './../../../../server/src/shared/PodHubTypes';
import { NgDataleechService } from '@specialforce/ng-dataleech';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss'],
})
export class UserlistComponent implements OnInit {
  constructor(
    protected dsService: NgDataleechService<User>,
    public fieldService: FieldService
  ) {
    dsService.datastoreName = 'users';
  }

  ngOnInit(): void {}
  onNewUserCreate(event: any): void {
    window.location.reload();
  }
}
