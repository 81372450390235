<div class="card-body p-2" style="background-color: #efefef">
  <div class="row">
    <div class="col" *ngIf="this.myDataObject">
      <b> {{ this.myDataObject.connectAccount }}</b>
      <br />
      <span>
        Zuletzt gesehen: {{ this.formatDate(this.myDataObject.lastRequested)
        }}<br />
        in Version: {{ this.myDataObject.requestedPodVersion }}<br />
        Aktiv bis:
        <span
          class="badge"
          [ngClass]="{
            'badge-danger': !this.isActive(),
            'badge-success': this.isActive()
          }"
        >
          <span *ngIf="!this.myDataObject.terminationDate">UNENDLICH </span>
          <span *ngIf="this.myDataObject.terminationDate"
            >{{ this.formatDate(this.myDataObject.terminationDate) }}
          </span>
        </span>
        <a
          *ngIf="showedit"
          [routerLink]="['/podauthlist', this.myDataObject._id]"
        >
          <i
            class="ri-edit-2-fill ml-2"
            style="position: relative; top: 5px"
          ></i
        ></a>
      </span>
    </div>
  </div>
</div>
