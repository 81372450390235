import { FieldService } from './../../FieldService';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from './../../shared/PodHubTypes';
import { Component, OnInit } from '@angular/core';
import {
  DataleechDetailsComponent,
  NgDataleechService,
} from '@specialforce/ng-dataleech';

@Component({
  selector: 'app-userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.scss'],
})
export class UserdetailsComponent
  extends DataleechDetailsComponent<User>
  implements OnInit {
  constructor(
    public activatedRoute: ActivatedRoute,
    public dataleechService: NgDataleechService<User>,
    public fieldService: FieldService,
    public router: Router
  ) {
    super(activatedRoute, dataleechService);
    this.datastoreName = 'users';
  }
  onUserDelete(event: any): void {
    setTimeout(() => {
      this.router.navigate(['userlist']);
    }, 1000);
  }
}
