import { FieldService } from './../FieldService';
import { ActivatedRoute } from '@angular/router';
import { PodAuth } from './../shared/PodHubTypes';
import { DataleechDetailsComponent, NgDataleechService } from '@specialforce/ng-dataleech';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-podauth',
  templateUrl: './podauthlist.component.html',
  styleUrls: ['./podauthlist.component.scss']
})
export class PodauthListComponent {



  constructor(public activatedRoute:ActivatedRoute,
    public dataleechService: NgDataleechService<PodAuth>,
    public fieldService:FieldService) 
  {  
   
    
    //this.dataleechService.datastoreName = 'auth';
    

  }


  public selectedItem(podAuth:PodAuth) {

  }
}
