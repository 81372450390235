import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadxOptions, UploadState } from 'ngx-uploadx';
import {Ufile} from '../shared/ufile';

@Component({
  selector: 'app-podupload',
  templateUrl: './podupload.component.html',
  styleUrls: ['./podupload.component.scss']
})
export class PoduploadComponent implements OnInit {
  // TODO: File type definieren
  @Input() podIdent: string;
  @Output('uploadedVersion') uploadedVersion: EventEmitter<boolean> = new EventEmitter();
  uploads: any[] = [];
  uploadxOptions: UploadxOptions = {
    allowedTypes: 'application/zip',
    concurrency: 1,
    headers: {token: localStorage.getItem('authTokenPodHub')},
    endpoint: '/api/upload/podversion',
  };
  version: string;
  comment: string;
  constructor() {}

  ngOnInit(): void {
  }
  onStateChanged(state: UploadState): void {
    console.log('state', state);
    // const file = this.uploads.find(item => item.uploadId === state.uploadId);
    // file ? file.update(state) : this.uploads.push(new Ufile(state));
    if (state.status == 'complete') {
      this.uploadedVersion.emit(true);
    }
  }
  public getUploadxOptions() {
    this.uploadxOptions.metadata = {podIdent: this.podIdent, version: this.version, comment: this.comment};
    return this.uploadxOptions;
  }

}
