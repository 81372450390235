import { NgBunyanLoggerService } from '@specialforce/ng-bunyan-logger';
import { ErrorHandler, Injectable } from '@angular/core';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(protected logger:NgBunyanLoggerService) {

  }

  handleError(error: Error) {
    this.logger.error({emsg:error.message, estack:error.stack, ename:error.name }, "Global Angular Error: " + error.message);  
  }
}