<app-pagewrapper>
  <div class="text-right mb-2">
    <lib-dataleech-create-button
      [fieldCreater]="this.fieldService.fc.user.email"
      requiredField="email"
      [initialDataObect]="{ superadmin: true }"
      promptmsg="Bitte Email eingeben"
      (onCreated)="this.onNewUserCreate($event)"
    >
      Neuer Benutzer
    </lib-dataleech-create-button>
  </div>
  <lib-dataleech-table-standalone
    title="Nutzer"
    [autoexpand]="true"
    [primaryFields]="['email', 'name', 'superadmin']"
    dataDisplayMode="table"
    datastorename="users"
    sortColumn="createdAt"
    sortDirection="desc"
    routerlinkIndexField="_id"
    [showColumnSettings]="false"
    [showDisplayModeSwitcher]="false"
    [populateWith]="this.fieldService.fc.user"
  >
  </lib-dataleech-table-standalone>
</app-pagewrapper>
