import { FsFile } from './../../../../../server/src/shared/PodHubTypes';
import { FieldService } from './../../FieldService';
import { ActivatedRoute, Router } from '@angular/router';
import { Pod, PodVersionMetadata } from './../../shared/PodHubTypes';
import { Component, OnInit } from '@angular/core';
import {
  DataleechDetailsComponent,
  NgDataleech2Service,
  NgDataleechService,
} from '@specialforce/ng-dataleech';
import * as moment from 'moment';
import { NgBunyanLoggerService } from '@specialforce/ng-bunyan-logger';

@Component({
  selector: 'app-poddetails',
  templateUrl: './poddetails.component.html',
  styleUrls: ['./poddetails.component.scss'],
})
export class PoddetailsComponent
  extends DataleechDetailsComponent<Pod>
  implements OnInit {
  public nvdisplay: boolean = false;
  public changePodidentDialog: boolean = false;
  public podVersions: FsFile<PodVersionMetadata>[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    public dataleechService: NgDataleechService<Pod>,
    public fieldService: FieldService,
    public versionService: NgDataleech2Service<FsFile<PodVersionMetadata>>,
    public router: Router,
    public logger:NgBunyanLoggerService
  ) {
    super(activatedRoute, dataleechService);
    this.datastoreName = 'pod';
    this.versionService.datastoreName = 'fs.files';
  }

  afterLoadData() {
    this.loadPodVersions();
  }

  onPodDelete(event: any): void {
    setTimeout(() => {
      this.router.navigate(['podlist']);
    }, 1000);
  }

  async loadPodVersions() {
    this.podVersions = await this.versionService
      .findMany({ finder: { 'metadata.podIdent': this.dataObject.podIdent }, sort:{"uploadDate":-1}})
      .toPromise();
   
  }

  protected formatDate(d) {
    return moment(d).locale('de').calendar();
  }

  public formatBytes(a, b = 2) {
    if (0 === a) return '0 Bytes';
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return (
      parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
      ' ' +
      ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
    );
  }
}
