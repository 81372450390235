import { GlobalErrorHandler } from './GlobarlErrorHandler';
import { FieldService } from './FieldService';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PodlistComponent } from './podlist/podlist.component';
import { PoduploadComponent } from './podupload/podupload.component';
import { UserlistComponent } from './userlist/userlist.component';
import { NgDataleechModule } from '@specialforce/ng-dataleech';
import { NgBunyanLoggerModule } from '@specialforce/ng-bunyan-logger';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { UploadxModule } from 'ngx-uploadx';
import { FormsModule } from '@angular/forms';
import { UserdetailsComponent } from './userlist/userdetails/userdetails.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { PoddetailsComponent } from './podlist/poddetails/poddetails.component';
import { PodauthListComponent } from './podauthlist/podauthlist.component';
import { PagewrapperComponent } from './shared/pagewrapper/pagewrapper.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AuthidcardComponent } from './podauthlist/authidcard/authidcard.component';
import { PodauthdetailsComponent } from './podauthlist/podauthdetails/podauthdetails.component';
import { NgAuthModule, NgRecoverpwModule } from '@specialforce/ng-common-components';
import { AuthService } from './auth/AuthService';
import { RecoverpwService } from './recoverpw/recoverpw.service';

@NgModule({
  declarations: [
    AppComponent,
    PodlistComponent,
    PoduploadComponent,
    UserlistComponent,
    UserdetailsComponent,
    PoddetailsComponent,
    PodauthListComponent,
    PagewrapperComponent,

    AuthidcardComponent,
    PodauthdetailsComponent,
  ],
  imports: [
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    UploadxModule,
    ProgressbarModule,
    BrowserAnimationsModule,
    NgDataleechModule,
    NgBunyanLoggerModule,
    FormsModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgAuthModule,
    NgRecoverpwModule
  ],
  providers: [

    { 
      // processes all errors
      provide: ErrorHandler, 
      useClass: GlobalErrorHandler
    },
    {
      provide: 'authService',
      useClass: AuthService
    },
    {
      provide: 'recoverService',
      useClass: RecoverpwService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: 'HTTPUPDATE_APIURL',
      useValue: '/api/',
    },
    FieldService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
