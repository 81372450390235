import { Component, Input, OnInit } from '@angular/core';
import { DataleechTableStandaloneComponent } from '@specialforce/ng-dataleech';
import { PodAuth } from 'src/app/shared/PodHubTypes';
import * as moment from 'moment';
@Component({
  selector: 'app-authidcard',
  templateUrl: './authidcard.component.html',
  styleUrls: ['./authidcard.component.scss'],
})
export class AuthidcardComponent implements OnInit {
  @Input() myDataObject: PodAuth;
  @Input() active: boolean;
  @Input() showedit: boolean = true;
  @Input() parentTable: DataleechTableStandaloneComponent<any>;
  constructor() {}

  public formatDate(d) {
    return moment(d).locale('de').calendar();
  }
  public isActive() {
    if (!this.myDataObject.terminationDate) {
      return true;
    }
    return moment(this.myDataObject.terminationDate) > moment();
  }
  ngOnInit(): void {}
}
