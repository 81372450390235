import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {throwError} from 'rxjs';
import { AbstractRecoverpwService } from '@specialforce/ng-common-components';

@Injectable({
  providedIn: 'root'
})
export class RecoverpwService extends AbstractRecoverpwService {

  constructor(private http: HttpClient) {
    super();
  }

  setPassword(code, password) {
    return this.http
      .get<any>('/api/auth/recoverPass', {params: new HttpParams().set('code', code).set('password', password)})
      .pipe(catchError(this.handleError));
  }
  checkCode(code) {
    return this.http.get<any>('/api/auth/checkRecoverCode', {params: new HttpParams().set('code', code)})
      .pipe(catchError(this.handleError));
  }

  handleError(errResp: HttpErrorResponse) {
    const errMsg = 'Unknown Error (' + errResp.status + ')';
    // todo create specific error msg from default error
    console.log('ErrorOn Http post', errResp);
    return throwError({msg: errMsg, nativeRespnse: errResp});
  }
}
