import { AuthResponseData, JWTUser } from './../shared/PodHubTypes';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AbstractAuthService } from '@specialforce/ng-common-components';


@Injectable({
    providedIn: 'root'
})
export class AuthService extends AbstractAuthService {

    constructor(private http: HttpClient, private router: Router) {
        super()
    }
    login(email: string, password: string): Promise<AuthResponseData> {
        const url = '/api/auth';
        const body = {email, password};
        return this.http.post<AuthResponseData>(url, body).toPromise();
    }

    requestPWRecovery(email: string): Promise<AuthResponseData> {
        const url = '/api/auth/requestNewPass';
        return this.http.get<AuthResponseData>(url, {params: new HttpParams().set('email', email)}).toPromise();
    }
    logout(): void {
        localStorage.removeItem('authTokenPodHub');
    }
    getUserObject(): JWTUser | false {
        if (!localStorage.getItem('authTokenPodHub')) {
            return false;
        }
        return jwt_decode(localStorage.getItem('authTokenPodHub'));
    }
    setUserObject(token: any) {
        localStorage.setItem('authTokenPodHub', token);
    }
    navigate() {
        this.router.navigate(['podlist']);
    }
}
