import { Pod } from './../../../../server/src/shared/PodHubTypes';
import { FieldService } from './../FieldService';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgDataleechService } from '@specialforce/ng-dataleech';
import { User } from '../shared/PodHubTypes';
import { Router } from '@angular/router';
import { ɵAnimationStyleNormalizer } from '@angular/animations/browser';

@Component({
  selector: 'app-podlist',
  templateUrl: './podlist.component.html',
  styleUrls: ['./podlist.component.scss'],
})
export class PodlistComponent implements OnInit {
  constructor(
    protected dataleech: NgDataleechService<Pod>,
    public fieldService: FieldService,
    public router: Router,
    private changeDetect: ChangeDetectorRef
  ) {
    this.dataleech.datastoreName = 'pod';
    this.dataleech.debug = true;
  }

  ngOnInit(): void {}

  onNewPodCreated(event: any): void {
    this.dataleech.reload();
    window.location.reload();
  }
}
